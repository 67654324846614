<template>
  <section id="card-navigation">
    <b-overlay :show="show" opacity="0.95" variant="dark">
      <b-row>
        <b-col cols="10">
          <h1>การ์ดเมสเสจ</h1>
          ข้อความในรูปแบบการ์ดที่รวมเนื้อหาต่างๆ เอาไว้ในที่เดียว <br />
          โดยจะแสดงเนื้อหาแบบภาพสไลด์ที่ลูกค้าสามารถปัดการ์ดไปด้านข้างเพื่อดูเนื้อหาบนการ์ดอื่นได้
        </b-col>
        <b-col cols="2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="add_sp()"
          >
            สร้างใหม่
          </b-button></b-col
        >
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
          จำนวน {{ totalRows }} การ์ด
          <div
            class="custom-search d-flex justify-content-end"
            style="margin-top: -2em"
          >
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      @click="goto_edit(props.row.id, props.row.type_card)"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>แก้ไข</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="goto_delete(props.row.id)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>ลบ</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <span v-if="props.column.field === 'name_card'"  @click="goto_edit(props.row.id, props.row.type_card)"
              >{{ props.row.name_card }}
            </span>

              <span v-if="props.column.field === 'type_card'"  @click="goto_edit(props.row.id, props.row.type_card)"
              >{{ props.row.type_card }}
            </span>

              <span v-if="props.column.field === 'count_items'"  @click="goto_edit(props.row.id, props.row.type_card)"
              >{{ props.row.count_items }}
            </span>



            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '15', '20']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>

            
          </vue-good-table>
        </b-col>
      </b-row>
      <MessageCard @loaddata="loaddata" ref="modaladdsp"></MessageCard>

      <template v-slot:overlay>
        <div class="overlay-alert">สำหรับแพ็กเกจ Standard ขึ้นไป</div>
      </template>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import MessageCard from "./MessageCard_type.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BBadge,
    MessageCard,
    BPagination,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      dataSplashPage: null,
      totalSplashPage: null,
      totalRows: "",
      rows: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "ขื่อ",
          field: "name_card",
        },
        {
          label: "ประเภท",
          field: "type_card",
        },
        {
          label: "จำนวนการ์ด",
          field: "count_items",
        },
        {
          label: "วันที่สร้าง",
          field: "create_datetime",
        },
        {
          label: "Action",
          field: "action",
        },
      ],

      searchTerm: "",
    };
  },
  mounted() {
    if (localStorage.getItem("packages") === "Pro") {
      this.show = false;
    } else if (localStorage.getItem("packages") === "Standard") {
      this.show = false;
    } else {
      this.show = true;
    }
    this.loaddata();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    async loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("get_card_message", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRows = response.data.total;
        });
    },
    add_sp() {
      this.$router.push({
        path: "/create/MessageCard",
        query: { id: 0 },
      });
    },
    goto_edit(id, type_card) {
      this.$router.push({
        path: "/edit/MessageCard",
        query: {
          id: id,
          type: type_card,
        },
      });
    },
    goto_delete(id) {
      this.$swal({
        title: "คุณต้องการลบข้อมูล",
        text: "คุณจะไม่สามารถย้อนกลับได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const params = {
            id: id,
          };
          api
            .post("del_card_message", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              this.loaddata();
              this.$swal({
                icon: "success",
                title: "สำเร็จ ",
                text: "ข้อมูลของคุณถูกลบไปแล้ว",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {});
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-table.bordered td {
  border-bottom-color: #ebe9f1;
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
}
.vgt-table.bordered th {
  border-bottom-color: #ebe9f1;
  border-left-color: #edeff0;
  border-right-color: #edeff0;
  background-color: #edeff0;
}
</style>
